export const environment = {
  production: true,
  api: {
    core: {
      uri: 'https://ecms-nyc.call-matrix.com',
      port: '9443',
      path: '/api/v1',
    },
  },
  softphone: {
    scheme: 'wss',
    server: 'ecms-nyc.call-matrix.com',
    port: '6061',
    path: '/ws',
    sipDomain: 'tenant_',
  },
  stripe: {
    key: 'pk_test_51MBHtwJ7lrcvxsq0axhu4LgQrsOUnCAGD5YufLsHxGQFstNYjwKLhiJchFQLMwK6T259MyquWETJWTKWpOLvU8rP00Z5XnIbKv',
  },
  recaptcha: {
    key: '6LdAYI4pAAAAAFQQLBVJzDlOWWZgLsqpu5UrLitX'
  }
};
